.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#over-map-box {
    position: absolute;
    z-index: 99;
    pointer-events: none;
    width: 100%;
    bottom: 5%;
}

.capture-pointer-events {
    pointer-events: initial;
}

.navbar-logo {
    height: 48px;
}

.bg-custom {
    background: linear-gradient(#007871, #225374);
}

.contact-button {
    width: 100%;
}

.float-right-top {
    position: absolute;
    right: 3px;
    top: 3px;
}

.flag {
    margin-right: 4px;
}

.flag-selected {
    border: 2px solid #666;
}

.flag-not-selected {
    border: 2px solid white;
}